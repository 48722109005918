import {
  adjectives,
  animals,
  colors,
  uniqueNamesGenerator,
} from "unique-names-generator";
/**
 * Utils
 */

const bcrypt = require("bcryptjs");

/**
 * Validate email address
 */
const validateEmailAddress = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 * Hash password
 * @param {*} user
 */
const hashPassword = (password) => {
  const salt = bcrypt.genSaltSync(10);
  return bcrypt.hashSync(password, salt);
};

/**
 * Compare password
 */
const comparePassword = (candidatePassword, trustedPassword) => {
  return bcrypt.compareSync(candidatePassword, trustedPassword);
};

const generateUsername = (userId, seed) => {
  let options = {
    dictionaries: [adjectives, colors, animals, [userId.toString()]],
    length: 4,
    separator: "",
    style: "capital",
  };
  if (seed) {
    options.seed = seed;
  }
  return uniqueNamesGenerator(options);
};

const getClosest = (elem, selector) => {
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};

const getTimeInMinutes = (time) => {
  const [h, m] = time.split(":");
  return parseInt(h, 10) * 60 + parseInt(m, 10);
};

const getPublicUrl = (url = "/") => {
  const publicVercelUrl = process.env.NEXT_PUBLIC_VERCEL_URL;

  if (
    publicVercelUrl.startsWith("http") ||
    publicVercelUrl.startsWith("https")
  ) {
    return publicVercelUrl + url;
  }

  return `https://${publicVercelUrl}${url}`;
};

module.exports = {
  getPublicUrl,
  generateUsername,
  getTimeInMinutes,
  comparePassword,
  hashPassword,
  validateEmailAddress,
  getClosest,
};
